export const isPasswordCaseChecked = (password: string | undefined): boolean => {
  if (!password) return false

  return !!(password.match(/[a-z]+/) && password.match(/[A-Z]+/))
}

export const isPasswordNumberLetterChecked = (password: string | undefined): boolean => {
  if (!password) return false

  return !!(password.match(/[0-9]+/) && (password.match(/[a-z]+/) || password.match(/[A-Z]+/)))
}

export const isPasswordSpecialCharChecked = (password: string | undefined): boolean => {
  return password ? !!password.match(/[^A-Za-z0-9]+/) : false
}

export const isPasswordLengthChecked = (password: string | undefined): boolean => {
  return password ? password.length >= 8 : false
}

export const isPasswordValid = (password: string | undefined): boolean => {
  if (!password) return false

  return (
    isPasswordCaseChecked(password) &&
    isPasswordLengthChecked(password) &&
    isPasswordNumberLetterChecked(password) &&
    isPasswordSpecialCharChecked(password)
  )
}
